import appHeader from "@/components/Header/header.vue";
import plainBtn from "@/components/buttons/plainBtn/plainBtn.vue";

export default {
  name: "chapterFour",
  components: {
    appHeader,
    plainBtn
  },
  data() {
    return {
      title: "Mon injection",
      icon: "home-icons/injection.png",
      instruction:
        "Cette vidéo vous indique les étapes pour préparer votre trousse d’urgence, identifier les situations dans lesquelles faire votre injection d’hydrocortisone, vous préparer et la réaliser. Nous vous laissons la regarder attentivement.",
      isLoaded: true
    };
  }
};
